import React, { useEffect, useMemo, useState } from "react";
import { Overlays } from "../overlays";
import SectionHeading from "../sectionHeading";
import Media from "../media";
import { IMAGE } from "../../common/config";
import { CtaButton } from "../ctaButton";
import { InputField } from "../forms/inputField";
import InputDropdown from "../forms/inputDropdown";
import { useDispatch, useSelector } from "react-redux";
import { home } from "../../redux/actions";
import {
  useAddEnquiryMutation,
  useGetClassAttemptQuery,
} from "../../api/services/enquire";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ValidationText from "../forms/validationText";
import { MOBILE_REGEX, getPath } from "../../utils";
import { useNavigate } from "react-router";

const schema = yup
  .object({
    fullName: yup.string().required("Full Name is required").max(50),
    emailID: yup.string().required("Email ID is required").email(),
    phoneNumber: yup
      .string()
      .matches(MOBILE_REGEX, "Invalid Mobile number")
      .required(),
    exam: yup.object().required("Exam is required"),
    attempt: yup.object().required("Attempt is required"),
  })
  .required();

const EnrollOverlay = ({ hasOverlay = true, selectedClass = null }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [submitEnquiry, { isLoading, isSuccess }] = useAddEnquiryMutation();

  useEffect(() => {
    if (isSuccess && hasOverlay) {
      dispatch(home.setShowEnrollOverlay());
    }
    if (isSuccess && !hasOverlay) {
      navigate(getPath("home"));
    }
  }, [isSuccess, dispatch, hasOverlay, navigate]);

  if (!hasOverlay) {
    return <EnrollForm submitEnquiry={submitEnquiry} isLoading={isLoading} />;
  }
  return (
    <>
      <Overlays
        overlayName={"enrollNow"}
        overlayConfig={{
          noOverlayClose: true,
          noOverlayHead: false,
          noOverlayBody: true,
          noOverlayFoot: false,
          onClickProp: () => {
            dispatch(home.setShowEnrollOverlay());
          },
          isDisabled: isLoading,
        }}
      >
        <EnrollForm
          submitEnquiry={submitEnquiry}
          isLoading={isLoading}
          preSelectedClass={selectedClass}
        />
      </Overlays>
    </>
  );
};

const EnrollForm = ({ submitEnquiry, isLoading, preSelectedClass }) => {
  const homeState = useSelector((state) => state?.app?.home);
  const masterData = useSelector(
    (state) => state?.masterApi?.queries?.["getMasterData(undefined)"]?.data
  );

  const [selectedClass, setSelectedClass] = useState(null);

  const { data: attemptData } = useGetClassAttemptQuery(
    homeState?.activeClassType || selectedClass?.id
  );

  const [selectedExam, setSelectedExam] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    let payload = new FormData();
    payload.append("name", data.fullName);
    payload.append("email", data.emailID);
    payload.append("mobile_no", data.phoneNumber);
    payload.append("class", homeState?.activeCourseType || data.exam.id);
    payload.append("attempt", data.attempt.id);
    payload.append("city", homeState?.selectedBranchName);
    submitEnquiry(payload);
  };

  useEffect(() => {
    if (preSelectedClass) {
      setSelectedClass(preSelectedClass);
      setValue("exam", preSelectedClass, {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [preSelectedClass, setValue]);

  const attempList = useMemo(
    () =>
      attemptData?.map((data) => ({
        ...data,
        title: data?.attempt,
      })),
    [attemptData]
  );

  useEffect(() => {
    setSelectedExam(null);
  }, [selectedClass]);

  return (
    <div className="enroll__grid">
      <div className="enroll__grid-lhs">
        <div className="enroll__thumb" data-aos="fade-right">
          <Media type={IMAGE} mediaURL={"enroll-thumb.png"} />
        </div>
      </div>
      <div className="enroll__grid-rhs" data-aos="fade-left">
        <div className="section__contentBox">
          <SectionHeading
            customCls={"section__heading"}
            tagName={"h2"}
            title={"Enrol The Batch"}
          />
        </div>
        <div className="enroll__branch">{homeState?.selectedBranchName}</div>
        <div className="enroll__form">
          <div className="login__form">
            <form className="form__wrap" onSubmit={handleSubmit(onSubmit)}>
              <div className="form__box">
                <div className="form__grid">
                  <div className="form__grid-item">
                    <InputField
                      registerFunction={register}
                      inputLbl={"Full Name"}
                      inputID={"fullName"}
                      handleInput={() => {}}
                      onBlur={() => {}}
                      onFocus={() => {}}
                      extraAttri={{
                        autofocus: "on",
                      }}
                      components={
                        <ValidationText
                          validationText={errors?.fullName?.message}
                        />
                      }
                    />
                  </div>
                  <div className="form__grid-item">
                    <InputField
                      registerFunction={register}
                      inputLbl={"Email Address"}
                      inputID={"emailID"}
                      inputType={"text"}
                      handleInput={() => {}}
                      onBlur={() => {}}
                      onFocus={() => {}}
                      extraAttri={{
                        inputMode: "email",
                        autoCapitalize: "off",
                      }}
                      components={
                        <ValidationText
                          validationText={errors?.emailID?.message}
                        />
                      }
                    />
                  </div>
                  <div className="form__grid-item">
                    <InputField
                      registerFunction={register}
                      parentExtraCls={"flag-IN"}
                      inputLbl={"Phone Number"}
                      inputID={"phoneNumber"}
                      inputType={"tel"}
                      handleInput={() => {}}
                      onBlur={() => {}}
                      onFocus={() => {}}
                      extraAttri={{
                        maxLength: "12",
                        inputMode: "numeric",
                      }}
                      components={
                        <ValidationText
                          validationText={errors?.phoneNumber?.message}
                        />
                      }
                    />
                  </div>
                  <div className="form__grid-item">
                    <InputDropdown
                      ddLbl={"Exam"}
                      ddId={"exam"}
                      ddList={masterData?.courses}
                      dropdownValue={selectedClass}
                      setdropdownValue={(data) => {
                        setValue("exam", data, {
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                        setSelectedClass(data);
                      }}
                      dropdownConfig={{
                        customCls: `dropdown--input`,
                      }}
                    />
                    <ValidationText validationText={errors?.exam?.message} />
                  </div>
                  <div className="form__grid-item">
                    <InputDropdown
                      ddLbl={"Attempt"}
                      ddId={"attempt"}
                      ddList={attempList}
                      dropdownValue={selectedExam}
                      setdropdownValue={(data) => {
                        setValue("attempt", data, {
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                        setSelectedExam(data);
                      }}
                      dropdownConfig={{ customCls: "dropdown--input" }}
                    />

                    <ValidationText validationText={errors?.attempt?.message} />
                  </div>
                </div>
              </div>
              <div className="enroll__cta">
                <CtaButton
                  btnCls={"btn btn__primary"}
                  btnText={"Enquiry Now"}
                  btnType="submit"
                  btnState={isLoading}
                  onClickProp={() => {}}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrollOverlay;
